<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
		 @clearSearch="clearSearch">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.agent_name" size="small" clearable placeholder="按代理商名称查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" clearable placeholder="按手机号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.status" placeholder="按状态查询" clearable size="small">
					<el-option label="禁用" :value="20">
					</el-option>
					<el-option label="审核中" :value="30">
					</el-option>
					<el-option label="正常" :value="40">
					</el-option>
				</el-select>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="agent_name" title="代理商名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="realname" title="真实姓名" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="phone" title="手机号" align="center" width="124" />
			<vxe-table-column slot="table-item" field="account_type" title="结算类型" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.account_type[row.account_type]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="balance" title="可提现余额" align="center" min-width="90px" />
			<vxe-table-column slot="table-item" field="total_money" title="累计分成金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.status[row.status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="user_name"  title="绑定账号" align="center" min-width="90px" />
			<vxe-table-column slot="table-item" field="remark" title="备注" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="180">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row)" v-if="$hasAccess('agent.agent/view')">详情</el-button>
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('agent.agent/edit')">编辑</el-button>
					<!-- <el-button
            size="small"
            plain
            @click="deleteOne(row)"
            v-if="$hasAccess('agent.agent/delete')"
          >删除</el-button> -->
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 详情 -->
		<el-dialog title="代理商详情" :visible.sync="detailDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
			<el-form ref="detailForm" label-width="140px" class="form" size="mini">
				<el-form-item label="代理商名称：">{{detailDialogFormData.agent_name}}
					<span>【{{types.status[detailDialogFormData.status]}}】</span>
				</el-form-item>
				<el-form-item label="真实姓名：">{{detailDialogFormData.realname}}</el-form-item>
				<el-form-item label="手机号：">{{detailDialogFormData.phone}}</el-form-item>
				<el-form-item label="结算类型：">按{{types.account_type[detailDialogFormData.account_type]||'未知类型'}}结算</el-form-item>
				<el-form-item label="可提现余额：">{{detailDialogFormData.balance}}</el-form-item>
				<el-form-item label="累计分成金额：">{{detailDialogFormData.total_money}}</el-form-item>
				<el-form-item label="银行卡号：">{{detailDialogFormData.cardno||'无'}}</el-form-item>
				<el-form-item label="开户支行地址：">{{detailDialogFormData.bank_address||'无'}}</el-form-item>
				<el-form-item label="支付宝账号：">{{detailDialogFormData.alipay_account||'无'}}</el-form-item>
				<el-form-item label="申请时间：">{{detailDialogFormData.register_time||'无'}}</el-form-item>
				<el-form-item label="审核时间：">{{detailDialogFormData.status==30?'审核中':(detailDialogFormData.check_time||'无')}}</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑代理商" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="140px" class="form">
				<el-form-item label="代理商名称">{{editDialogFormOriData.agent_name}}</el-form-item>
				<el-form-item label="状态" prop="status">
					<el-radio-group v-model="editDialogFormData.status">
						<el-radio :label="20">禁用</el-radio>
						<el-radio :label="40">正常</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="结算类型" prop="account_type">
					<el-radio-group v-model="editDialogFormData.account_type">
						<el-radio :label="10">周</el-radio>
						<el-radio :label="20">月</el-radio>
						<el-radio :label="30">季度</el-radio>
						<el-radio :label="40">半年</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="屏蔽设备">
					<el-select
							v-model="device_ids"
							size="small"
							style="width: 80%"
							multiple
							filterable
							remote
							reserve-keyword
							clearable
							placeholder="屏蔽设备"
							:remote-method="remoteGroup"
							:loading="groupLoading">
						<el-option
								v-for="item in groupList"
								:key="item.device_id"
								:label="item.device_name"
								:value="item.device_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="绑定账号">
					<el-select
							v-model="editDialogFormData.user_ids"
							size="small"
							style="width: 80%"
							filterable
							remote
							reserve-keyword
							clearable
							placeholder="选择账号"
							:remote-method="remoteUser"
							:loading="userLoading">
						<el-option
								v-for="item in userList"
								:key="item.user_id"
								:label="item.username"
								:value="item.user_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="editDialogFormData.remark" controls-position="right" :min="0" :step="1" :precision="2" style="width: 80%;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()" :disabled="editDisabled" v-if="$hasAccess('agent.agent/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'agent-agent-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '代理商',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				groupLoading:[],
				userLoading:[],
				searchFormData: {},
				agent_id:0,
				types: {
					account_type: {
						10: '周',
						20: '月',
						30: '季度',
						40: '半年'
					},
					status: {
						20: '禁用',
						30: '审核中',
						40: '正常'
					}
				},
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				editDialogFormOriData: {},
				editNeedData: ['agent_id', 'status', 'account_type','device_ids','user_ids'],
				rules: {
					status: [{
						required: true,
						message: '请选择状态',
						trigger: 'change'
					}],
					account_type: [{
						required: true,
						message: '请选择结算类型',
						trigger: 'change'
					}],
				},
				device_ids:[],
				user_ids:[],
				groupList: [],
				userList: [],
				groupLoading: false,
				userLoading: false
			}
		},
		computed: {
			editDisabled() {
				for (let item of this.editNeedData) {
					if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
						return false
					}
				}
				return false
			}
		},
		mounted() {},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				const res = await this.$api.Agent.GetAgentList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 设备
			async remoteGroup (query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetDeviceAgentList(params, {
						device_name: query,
						agent_id: this.agent_id
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 设备
			async remoteUser (query) {
				if (query !== '') {
					this.userLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetAdminUserList(params, {
						device_name: query,
						agent_id: this.agent_id
					})
					this.userList = res.data
					this.userLoading = false
				} else {
					this.userList = []
				}
			},
			// 重置form
			resetFormData() {
				this.detailDialogFormData = {}
				this.editDialogFormData = {}
				this.editDialogFormOriData = {}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
				this.groupLoading = false
				this.groupList=[]
				this.userLoading = false
				this.userList=[]
				this.device_ids=[]
				this.user_ids=[]
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.editDialogShow = false
				this.groupLoading = false
				this.groupList=[]
				this.userLoading = false
				this.userList=[]
				this.device_ids=[]
				this.user_ids=[]
			},
			// 查看详情
			async detailOne(row) {
				this.resetFormData()
				const params = {
					token: this.$store.state.user.token,
					agent_id: row.agent_id
				}
				const res = await this.$api.Agent.GetAgentDetail(params)
				this.detailDialogFormData = res
				this.detailDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData()
				this.agent_id=row.agent_id;
				this.remoteGroup();
				this.remoteUser();
				const params = {
					token: this.$store.state.user.token,
					agent_id: row.agent_id
				}
				const res = await this.$api.Agent.GetAgentDetail(params)
				if (res) {
					if (res.device_ids){
						const groupIds = res.device_ids.toString().split(',')
						for (let i = 0; i < groupIds.length; i++) {
							groupIds[i] = parseInt(groupIds[i])
						}
						this.device_ids = groupIds;
					}
					const {
						agent_id,
						agent_name,
						status,
						account_type,
						device_ids,
						user_ids,
						remark
					} = res
					this.editDialogFormData = {
						agent_id,
						status,
						account_type,
						device_ids,
						user_ids,
						remark
					}
					this.editDialogFormOriData = {
						agent_id,
						agent_name,
						status,
						account_type,
						device_ids,
						user_ids,
						remark
					}
				}
				this.editDialogShow = true
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						this.editDialogFormData.device_ids = this.device_ids.join(',');
					   if (window.location.href.includes('kejue.cloud.szkejue.com')){
                           delete this.editDialogFormData.user_ids;
					   }
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						await this.$api.Agent.EditAgent(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
